<template>
  <v-layout class="d-block">
    <v-row class="mb-12">
      <v-col
        cols="12"
        v-if="clone.logo && clone.logo.url"
        class="d-flex justify-end px-6"
      >
        <v-hover v-if="!loading" :disabled="action === 'show'">
          <template v-slot:default="{ hover }">
            <v-img
              :aspect-ratio="16 / 9"
              :lazy-src="clone.logo.url"
              :src="clone.logo.url"
              contain
              max-width="20%"
            >
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        fab
                        color="white"
                        :loading="loading"
                        v-on="on"
                      >
                        <template v-slot:loader>
                          <v-progress-circular
                            color="primary"
                            indeterminate
                            size="16"
                            width="2"
                          >
                          </v-progress-circular>
                        </template>
                        <v-icon
                          color="error"
                          :loading="loading"
                          @click="deleteLogo()"
                          >mdi-trash-can-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span style="font-size: 10px">
                      {{ $t("common.buttons.delete") }}
                    </span>
                  </v-tooltip>
                </v-overlay>
              </v-fade-transition>
            </v-img>
          </template>
        </v-hover>
      </v-col>
      <v-col
        v-else-if="['new', 'edit'].includes(action) && !(clone.logo && clone.logo.url)"
        cols="12"
        class="d-flex justify-end px-6"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-img
              :aspect-ratio="16 / 9"
              :lazy-src="require('@/assets/img/logo-placeholder.jpg')"
              :src="require('@/assets/img/logo-placeholder.jpg')"
              max-width="20%"
            >
              <v-fade-transition>
                <v-overlay v-show="hover" absolute color="white">
                  <CropperPhotoDialog
                    :append-icon="false"
                    :hide-input="true"
                    type="logo"
                    :reference="'logo_primary'"
                    :disabled="disableUploadLogo"
                    @update:logo="updateLogo($event)"
                    @update:documents="item.updateLogo = $event"
                    @rule:size="snackbar = $event"
                  />
                </v-overlay>
              </v-fade-transition>
            </v-img>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12">
        <v-container fluid>
          <v-card outlined class="card-hover" v-if="['new', 'edit'].includes(action)">
            <v-app-bar dense elevation="0" flat>
              <h3 class="font-weight-bold">{{ $t("locations.titles.images") }}</h3>
              <v-spacer></v-spacer>
              <v-img
                class="mx-4"
                :src="require('@/assets/icons/image.svg')"
                contain
                height="25px"
                max-height="25px"
                width="25px"
                max-width="25px"
              />
            </v-app-bar>
            <v-row
              class="d-flex justify-start ma-0 pa-0"
              v-if="['new', 'edit'].includes(action)"
            >
              <v-col
                v-if="clone.images.length < 5"
                cols="12"
                md="3"
              >
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-img
                      :aspect-ratio="16 / 9"
                      :lazy-src="require('@/assets/img/preview-img.png')"
                      :src="require('@/assets/img/preview-img.png')"
                    >
                      <v-fade-transition>
                        <v-overlay v-show="hover" absolute color="white">
                          <CropperPhotoDialog
                            :append-icon="false"
                            :hide-input="true"
                            :reference="`image_empty`"
                            type="image"
                            :disabled="disableUploadImages"
                            @update:images="updateImageList($event)"
                            @update:documents="item.updateDocuments = $event"
                            @rule:size="snackbar = $event"
                          />
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </template>
                </v-hover>
              </v-col>
              <v-col
                v-for="(image, key) in sortedImages"
                :key="key"
                cols="12"
                md="3"
              >
                <v-hover v-if="!loading" :disabled="action === 'show'">
                  <template v-slot:default="{ hover }">
                    <v-img
                      :aspect-ratio="16 / 9"
                      :lazy-src="image.url"
                      :src="image.url"
                    >
                      <v-fade-transition>
                        <v-overlay v-if="hover" absolute color="white">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                fab
                                color="white"
                                :loading="loading"
                                v-on="on"
                              >
                                <template v-slot:loader>
                                  <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="16"
                                    width="2"
                                  >
                                  </v-progress-circular>
                                </template>
                                <v-icon
                                  color="error"
                                  :loading="loading"
                                  @click="deleteImage(key)"
                                  >mdi-trash-can-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span style="font-size: 10px">
                              {{ $t("common.buttons.delete") }}
                            </span>
                          </v-tooltip>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            outlined
            class="card-hover"
            v-else-if="
              action === 'show' && clone.images && clone.images.length > 0
            "
          >
            <v-app-bar dense elevation="0" flat>
              <h3 class="font-weight-bold">{{ $t("locations.titles.images") }}</h3>
              <v-spacer></v-spacer>
              <v-img
                class="mx-4"
                :src="require('@/assets/icons/image.svg')"
                contain
                height="25px"
                max-height="25px"
                width="25px"
                max-width="25px"
              />
            </v-app-bar>
            <v-row class="d-flex justify-start ma-0 pa-0">
              <v-col
                v-for="(image, key) in sortedImages"
                :key="key"
                cols="12"
                md="3"
              >
                <v-img
                  :aspect-ratio="16 / 9"
                  :lazy-src="image.url"
                  :src="image.url"
                >
                </v-img>
              </v-col>
            </v-row>
          </v-card>
          <v-card outlined class="card-hover my-4">
            <v-app-bar dense elevation="0" flat>
              <h3 class="font-weight-bold">{{ $t("locations.titles.socialMedia") }}</h3>
              <v-spacer></v-spacer>
              <v-img
                class="mx-4"
                :src="require('@/assets/icons/link.svg')"
                contain
                height="25px"
                max-height="25px"
                width="25px"
                max-width="25px"
              />
            </v-app-bar>
            <v-card-text>
              <v-row v-if="action === 'show'">
                <v-col cols="12">
                  <span
                    style="
                      display: flex;
                      border-radius: 0.2em;
                      background-color: rgb(250, 250, 250);
                      padding: 20px;
                    "
                  >
                    <h2 class="mr-2 font-weight-bold">Video:</h2>
                    {{ item.socialMedia.video }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span
                    style="
                      display: flex;
                      border-radius: 0.2em;
                      background-color: rgb(250, 250, 250);
                      padding: 20px;
                    "
                  >
                    <h2 class="mr-2 font-weight-bold">Facebook:</h2>
                    {{ item.socialMedia.facebook }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span
                    style="
                      display: flex;
                      border-radius: 0.2em;
                      background-color: rgb(250, 250, 250);
                      padding: 20px;
                    "
                  >
                    <h2 class="mr-2 font-weight-bold">Instagram:</h2>
                    {{ item.socialMedia.instagram }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span
                    style="
                      display: flex;
                      border-radius: 0.2em;
                      background-color: rgb(250, 250, 250);
                      padding: 20px;
                    "
                  >
                    <h2 class="mr-2 font-weight-bold">Twitter:</h2>
                    {{ item.socialMedia.twitter }}
                  </span>
                </v-col>
              </v-row>
              <v-row v-if="['new', 'edit'].includes(action)">
                <v-col cols="12">
                  <v-text-field
                    v-model="item.socialMedia.video"
                    label="Video"
                    color="primary"
                    outlined
                    dense
                    placeholder="https://..."
                    append-icon="mdi-link"
                    :messages="$t('locations.titles.supportLinks')"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="item.socialMedia.facebook"
                    label="Facebook"
                    color="primary"
                    outlined
                    dense
                    placeholder="https://..."
                    append-icon="mdi-facebook"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="item.socialMedia.instagram"
                    label="Instagram"
                    color="primary"
                    outlined
                    dense
                    placeholder="https://..."
                    append-icon="mdi-instagram"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="item.socialMedia.twitter"
                    label="Twitter"
                    color="primary"
                    outlined
                    dense
                    placeholder="https://..."
                    append-icon="mdi-twitter"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      app
      bottom
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('common.buttons.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
  import _ from "lodash"
  import i18n from "@/plugins/i18n";
  import MapBox from "@/components/vueMapbox/MapBox.vue"
  import CropperPhotoDialog from "@/components/common/CropperPhotoDialog.vue"
  import SlideShowComponent from "@/components/common/SlideShowComponent.vue"
  import { mapGetters, mapActions } from "vuex"
  import LanguageService from "@/services/LanguajeService"

  export default {
    name: "TabMediaLocation",
    components: { CropperPhotoDialog },
    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
    },

    data: () => ({
      ready: false,
      loading: false,
      isImageHover: false,
      isLogoHover: false,
      clone: {},
      text: i18n.t("common.rules.size"),
      snackbar: false
    }),

    computed: {
      ...mapGetters({}),
      disableUploadLogo() {
        return !!this.item.logo
      },
      disableUploadImages() {
        return this.clone.images.length === 5
      },
      sortedImages() {
        return _.orderBy(this.clone.images, ['id'], ['asc']);
      }
    },

    created() {
      this.clone = _.cloneDeep(this.item)
    },

    methods: {
      ...mapActions({}),
      updateImageList(e) {
        let images = [...this.clone.images]
        images.push(e)
        this.$set(this.clone, "images", images)
        this.$emit("update:images", this.clone.images)

        this.item.updateDocuments = true
      },
      deleteImage(key) {
        this.loading = true

        let images = [...this.clone.images]
        images.splice(key, 1)

        this.$set(this.clone, "images", images)
        this.$emit("update:images", this.clone.images)

        this.item.updateDocuments = true
        this.loading = false
      },
      updateLogo(e) {
        this.clone.logo = e
        this.$emit("update:logo", e)
        this.item.updateLogo = true
      },
      deleteLogo() {
        this.clone.logo = null
        this.$emit("update:logo", this.clone.logo)
        this.item.updateLogo = true
      },
    },
  }
</script>

<style scoped lang="scss">
  .chip-image__input-label {
    transition: all 200ms;
    background: #03ac8c;
    color: white;
    padding: 4px;
    margin: 0 15px;
    position: relative;
    border-radius: 0 0 0.3em 0.3em;
  }

  .chip-logo__input-label {
    transition: all 200ms;
    background: #03ac8c;
    color: white;
    padding: 4px;
    margin: 0 15px;
    position: relative;
    border-radius: 0 0 0.3em 0.3em;
  }

  :deep(.images__is-hover) {
    display: none;
  }

  .column-image__hover i {
    transition: all 200ms;
    position: relative;
    top: 20px;
    opacity: 0;
  }

  .column-image__hover:hover {
    & i {
      top: 0;
      opacity: 100%;
    }
  }

  .column-logo__hover i {
    transition: all 200ms;
    position: relative;
    top: 20px;
    opacity: 0;
  }

  .column-logo__hover:hover {
    & i {
      top: 0;
      opacity: 100%;
    }
  }

  .card-hover {
    box-shadow: none;
    transition: all 200ms ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }
</style>
