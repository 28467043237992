<template>
  <v-layout class="d-block">
    <v-row class="mb-12">
      <v-col cols="12">
        <v-card outlined class="card-hover fill-height">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("locations.titles.extraData") }}</h3>
            <v-spacer></v-spacer>
            <v-img
              class="mx-4"
              :src="require('@/assets/icons/firstline.svg')"
              contain
              height="25px"
              max-height="25px"
              width="25px"
              max-width="25px"
            />
          </v-app-bar>
            <v-card-text>
              <v-row v-if="action === 'show'">
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("locations.fields.classificationType") }}:</h3>
                    <div>
                      <div v-for="(classification, index) in findClassifications(item)" :key="index">
                        {{ classification }}
                      </div>
                      <div v-if="!findClassifications(item).length">-</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-form v-if="['new', 'edit'].includes(action)" ref="formMain">
                <v-row>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-autocomplete
                        v-model="item.classificationTypes"
                        :items="classifications"
                        :label="$t('locations.fields.classificationType')"
                        item-value="id"
                        item-text="name"
                        item-color="primary"
                        class="required"
                        color="primary"
                        required
                        dense
                        outlined
                        multiple
                        chips
                        small-chips
                        deletable-chips
                        clearable
                      >
                        <template v-slot:selection="{ item: classification, index }">
                          <v-chip
                            v-if="index === 0"
                            small
                            close
                            @click:close="removeClassification(classification.id)"
                            class="mr-1"
                          >
                            {{ classification.name }}
                          </v-chip>
                          <span v-else-if="index === 1" class="grey--text text-caption">
                            +{{ item.classificationTypes.length - 1 }} {{ $t('common.titles.others')  }}
                          </span>
                        </template>
                      </v-autocomplete>
                    </span>
                  </v-col>
                </v-row>
              </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
  import _ from "lodash";
  import { mapGetters } from "vuex"
  import LanguajeService from "@/services/LanguajeService";

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    components: {},
    name: "TabExtraDataLocation",
    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
    },

    async created() {
      await this.$store.dispatch('locations/fetchClassificationTypes');
      
      // Migrar de classificationType a classificationTypes si es necesario
      if (this.item.classificationType && !this.item.classificationTypes) {
        this.$set(this.item, 'classificationTypes', this.item.classificationType ? [this.item.classificationType] : []);
      } else if (!this.item.classificationTypes) {
        this.$set(this.item, 'classificationTypes', []);
      }
    },

    computed: {
      ...mapGetters({
        classifications: "locations/GET_CLASSIFICATIONS",
      }),
    },

    methods: {
      findClassifications(item) {
        if (!item.classificationTypes || !Array.isArray(item.classificationTypes) || !item.classificationTypes.length) {
          return [];
        }
        
        return item.classificationTypes.map(typeId => {
          const classification = _.find(this.classifications, { id: typeId });
          return classification ? this.translate(classification.languageKey) : '';
        }).filter(Boolean);
      },
      translate(key) {
        return LanguajeService.getKey3(key);
      },
      removeClassification(id) {
        const index = this.item.classificationTypes.indexOf(id);
        if (index >= 0) {
          const newTypes = [...this.item.classificationTypes];
          newTypes.splice(index, 1);
          this.$set(this.item, 'classificationTypes', newTypes);
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .fill-icon__color {
    filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(180deg)
      brightness(400%) contrast(88%);
  }
  .card-hover {
    box-shadow: none;
    transition: all 200ms ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }

  :deep(.v-input--selection-controls.v-input) {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between !important;
    align-items: center !important;
  }

  :deep(.v-input--hide-details > .v-input__control > .v-input__slot) {
    display: contents;
  }

  :deep(.v-input__control) {
    align-items: flex-end;
  }

  :deep(.theme--light.v-label) {
    left: 0px;
    right: auto;
    position: relative;
  }
</style>
